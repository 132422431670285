"use client";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";

import { Button } from "../../../lib/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "../../../lib/ui/form";
import { Input } from "../../../lib/ui/input";
import { Switch } from "../../../lib/ui/switch";
import axiosInstance from "../../../../axiosInterceptor";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../lib/ui/select";

const wildCardOptions = "\"{poNumbers}\",\"{originalSubject}\",\"{originalBody}\" - only works with forwarded emails";
const formSchema = z.object({
    id: z.number().int(),
    orderSubmissionSourceId: z.string().min(1, { message: "Required" }),
    vendorNameFromFile: z.string().min(1, { message: "Required" }),
    sendFromEmailSourceModelId: z.string().min(1, { message: "Required" }),
    submitEmails: z.string().min(1, { message: "Required" }),
    submitCcEmails: z.string(),
    submitBccEmails: z.string(),
    submitEmailSubjectLine: z.string().min(1, { message: "Required" }),
    submitEmailBodyText: z.string(),
    vendorProvidedAccountNumber: z.string(),
    vendorWantsTrimmedItemDescription: z.boolean(),
    vendorRegexForItemDescription: z.string(),
    vendorWantsNonDailySubmission: z.boolean(),
    vendorWantsAllAlignedLeft: z.boolean(),
    vendorCustomSubmitEveryXDay: z.string(),
    vendorWantsPDF: z.boolean(),
    vendorWantsSpreadsheet: z.boolean(),
    vendorWantsForwardedEmail: z.boolean(),
    vendorWantsAllData: z.boolean(),
    submitFileHeaderRowIndex: z.string().min(1, { message: "Required" }),
    submitFileTemplate: z.instanceof(File).optional(),
    mapBatch: z.string(),
    mapRep: z.string(),
    mapVendorAddress: z.string(),
    mapVendorPhone: z.string(),
    mapVendorFax: z.string(),
    mapPo: z.string(), 
    mapOrderDate: z.string(),
    mapReqShipDate: z.string(),
    mapShipVia: z.string(),
    mapShipTo: z.string(),
    mapAddress1: z.string(),
    mapCareOf: z.string(),
    mapAttention: z.string(),
    mapAddress2: z.string(),
    mapCity: z.string(),
    mapState: z.string(),
    mapZipCode: z.string(),
    mapPhone: z.string(),
    mapEmail: z.string(),
    mapItemDescription: z.string(),
    mapUnitPrice: z.string(),
    mapQty: z.string(),
    mapProvidedAccountNumber: z.string(),
    mapTotal: z.string(),
    staticFileData1: z.string(),
    mapStaticFileData1: z.string(),
    staticFileData2: z.string(),
    mapStaticFileData2: z.string(),
    staticFileData3: z.string(),
    mapStaticFileData3: z.string(),
    staticFileData4: z.string(),
    mapStaticFileData4: z.string(),
    staticFileData5: z.string(),
    mapStaticFileData5: z.string(),
    activeStatus: z.boolean(),
    deletedStatus: z.boolean(),
    vendorSourceAliases: z.array(z.object({
        id: z.number().int(),
        parentVendorSourceId: z.number().int(),
        vendorAliasNameFromFile: z.string().min(1, { message: "Required" }),
        vendorProvidedAccountNumber: z.string(),
        activeStatus: z.boolean(),
        deletedStatus: z.boolean(),
    }))
});

export function EditOrderSubmissionVendorSource() {
    const { vendorId } = useParams();
    const [fileTemplate, setFileTemplate] = useState<{ fileName: string, fileData: string } | null>(null);
    const [aliases, setAliases] = useState<z.infer<typeof formSchema>['vendorSourceAliases']>([]);
    const [editingAlias, setEditingAlias] = useState<z.infer<typeof formSchema>['vendorSourceAliases'][0] | null>(null);
    const [emailList, setEmailList] = useState([]);
    const [orderSubmissionList, setOrderSubmissionList] = useState([]);

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            id: 0,
            orderSubmissionSourceId: "",
            vendorNameFromFile: "",
            sendFromEmailSourceModelId: "",
            submitEmails: "",
            submitCcEmails: "",
            submitBccEmails: "",
            submitEmailSubjectLine: "",
            submitEmailBodyText: "",
            vendorProvidedAccountNumber: "",
            vendorWantsTrimmedItemDescription: false,
            vendorRegexForItemDescription: "",
            vendorWantsNonDailySubmission: false,
            vendorWantsAllAlignedLeft: false,
            vendorCustomSubmitEveryXDay: "",
            vendorWantsPDF: false,
            vendorWantsSpreadsheet: false,
            vendorWantsForwardedEmail: false,
            vendorWantsAllData: false,
            submitFileHeaderRowIndex: "",
            mapBatch: "",
            mapRep: "",
            mapVendorAddress: "",
            mapVendorPhone: "",
            mapVendorFax: "",
            mapPo: "",
            mapOrderDate: "",
            mapReqShipDate: "",
            mapShipVia: "",
            mapShipTo: "",
            mapAddress1: "",
            mapCareOf: "",
            mapAttention: "",
            mapAddress2: "",
            mapCity: "",
            mapState: "",
            mapZipCode: "",
            mapPhone: "",
            mapEmail: "",
            mapItemDescription: "",
            mapUnitPrice: "",
            mapQty: "",
            mapProvidedAccountNumber: "",
            mapTotal: "",
            staticFileData1: "",
            mapStaticFileData1: "",
            staticFileData2: "",
            mapStaticFileData2: "",
            staticFileData3: "",
            mapStaticFileData3: "",
            staticFileData4: "",
            mapStaticFileData4: "",
            staticFileData5: "",
            mapStaticFileData5: "",
            activeStatus: true,
            deletedStatus: false,
            vendorSourceAliases: [],
        }
    });

    useEffect(() => {
        if (vendorId && vendorId !== "0") {
            axiosInstance.get(`/api/order-submission/vendor/get/${vendorId}`)
                .then(response => {
                    const data = response.data;
                    form.setValue('id', Number(vendorId));
                    form.setValue('orderSubmissionSourceId', data.orderSubmissionSourceId);
                    form.setValue('vendorNameFromFile', data.vendorNameFromFile);
                    form.setValue('sendFromEmailSourceModelId', data.sendFromEmailSourceModelId);
                    form.setValue('submitEmails', data.submitEmails);
                    form.setValue('submitCcEmails', data.submitCcEmails);
                    form.setValue('submitBccEmails', data.submitBccEmails);
                    form.setValue('submitEmailSubjectLine', data.submitEmailSubjectLine);
                    form.setValue('submitEmailBodyText', data.submitEmailBodyText);
                    form.setValue('vendorProvidedAccountNumber', data.vendorProvidedAccountNumber);
                    form.setValue('vendorWantsTrimmedItemDescription', data.vendorWantsTrimmedItemDescription);
                    form.setValue('vendorRegexForItemDescription', data.vendorRegexForItemDescription);
                    form.setValue('vendorWantsNonDailySubmission', data.vendorWantsNonDailySubmission);
                    form.setValue('vendorWantsAllAlignedLeft', data.vendorWantsAllAlignedLeft);
                    form.setValue('vendorCustomSubmitEveryXDay', data.vendorCustomSubmitEveryXDay != null ? data.vendorCustomSubmitEveryXDay.toString() : '');
                    form.setValue('vendorWantsPDF', data.vendorWantsPDF);
                    form.setValue('vendorWantsSpreadsheet', data.vendorWantsSpreadsheet);
                    form.setValue('vendorWantsForwardedEmail', data.vendorWantsForwardedEmail);
                    form.setValue('vendorWantsAllData', data.vendorWantsAllData);
                    form.setValue('submitFileHeaderRowIndex', data.submitFileHeaderRowIndex != null ? data.submitFileHeaderRowIndex.toString() : '');
                    form.setValue('mapBatch', data.mapBatch);
                    form.setValue('mapRep', data.mapRep);
                    form.setValue('mapVendorAddress', data.mapVendorAddress);
                    form.setValue('mapVendorPhone', data.mapVendorPhone);
                    form.setValue('mapVendorFax', data.mapVendorFax);
                    form.setValue('mapPo', data.mapPo);
                    form.setValue('mapOrderDate', data.mapOrderDate);
                    form.setValue('mapReqShipDate', data.mapReqShipDate);
                    form.setValue('mapShipVia', data.mapShipVia);
                    form.setValue('mapShipTo', data.mapShipTo);
                    form.setValue('mapAddress1', data.mapAddress1);
                    form.setValue('mapCareOf', data.mapCareOf);
                    form.setValue('mapAttention', data.mapAttention);
                    form.setValue('mapAddress2', data.mapAddress2);
                    form.setValue('mapCity', data.mapCity);
                    form.setValue('mapState', data.mapState);
                    form.setValue('mapZipCode', data.mapZipCode);
                    form.setValue('mapPhone', data.mapPhone);
                    form.setValue('mapEmail', data.mapEmail);
                    form.setValue('mapItemDescription', data.mapItemDescription);
                    form.setValue('mapUnitPrice', data.mapUnitPrice);
                    form.setValue('mapQty', data.mapQty);
                    form.setValue('mapProvidedAccountNumber', data.mapProvidedAccountNumber);
                    form.setValue('mapTotal', data.mapTotal);
                    form.setValue('staticFileData1', data.staticFileData1);
                    form.setValue('mapStaticFileData1', data.mapStaticFileData1);
                    form.setValue('staticFileData2', data.staticFileData2);
                    form.setValue('mapStaticFileData2', data.mapStaticFileData2);
                    form.setValue('staticFileData3', data.staticFileData3);
                    form.setValue('mapStaticFileData3', data.mapStaticFileData3);
                    form.setValue('staticFileData4', data.staticFileData4);
                    form.setValue('mapStaticFileData4', data.mapStaticFileData4);
                    form.setValue('staticFileData5', data.staticFileData5);
                    form.setValue('mapStaticFileData5', data.mapStaticFileData5);
                    form.setValue('activeStatus', data.activeStatus);
                    form.setValue('deletedStatus', data.deletedStatus);
                    setAliases(data.vendorSourceAliases || []);
                    if (data.submitFileTemplate) {
                        setFileTemplate({
                            fileName: data.submitFileTemplateFilename,
                            fileData: data.submitFileTemplate
                        });
                    }
                })
                .catch(error => {
                    console.error('Failed to fetch data:', error);
                });
        }
        axiosInstance.get(`/api/email-sources/email-list`)
            .then(response => {
                setEmailList(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch email sources:', error);
            });
        axiosInstance.get(`/api/order-submission/order-submission-list`)
            .then(response => {
                setOrderSubmissionList(response.data);
            })
            .catch(error => {
                console.error('Failed to fetch email sources:', error);
            });
    }, [vendorId, form]);

    function onSubmit(values: z.infer<typeof formSchema>) {
        values.vendorSourceAliases = aliases; 
        values.vendorCustomSubmitEveryXDay = parseNullableIntToString(values.vendorCustomSubmitEveryXDay);
        const selectedEmail = emailList.find(email => email.item1 === values.sendFromEmailSourceModelId);
        if (selectedEmail) {
            values.sendFromEmailSourceModelId = selectedEmail.item2;
        }
        const selectedSubmissionSource = orderSubmissionList.find(source => source.item1 === values.orderSubmissionSourceId);
        if (selectedSubmissionSource) {
            values.orderSubmissionSourceId = selectedSubmissionSource.item2;
        }
        if (values.submitFileTemplate) {
            const reader = new FileReader();
            reader.readAsDataURL(values.submitFileTemplate);
            reader.onload = () => {
                const base64Data = reader.result as string;
                const submitFileTemplate = base64Data.split(",")[1];// Remove the data URL prefix
                const submitFileTemplateFileName = values.submitFileTemplate.name;

                const payload = {
                    ...values,
                    submitFileTemplate,
                    submitFileTemplateFileName
                };
                submitFormData(payload);
            };
        } else {
            submitFormData(values);
        }
    }

    const submitFormData = (payload: any) => {
        axiosInstance.post('/api/order-submission/vendor/save', payload, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => {
                if (response.status === 200 || response.status === 204) {
                    window.location.href = window.location.origin + "/order-submission/vendor";
                } else {
                    console.log('Failed to save data');
                }
            })
            .catch(error => {
                console.error('Request failed:', error);
            });
    };

    const addAlias = () => {
        setEditingAlias({
            id: 0,
            parentVendorSourceId: Number(vendorId),
            vendorAliasNameFromFile: "",
            vendorProvidedAccountNumber: "",
            activeStatus: true,
            deletedStatus: false
        });
    };

    const saveAlias = (alias: z.infer<typeof formSchema>['vendorSourceAliases'][0]) => {
        if (alias.id === 0) {
            setAliases([...aliases, { ...alias, id: 0 }]);
        } else {
            setAliases(aliases.map(a => (a.id === alias.id ? alias : a)));
        }
        setEditingAlias(null);
    };

    const editAlias = (alias: z.infer<typeof formSchema>['vendorSourceAliases'][0]) => {
        setEditingAlias(alias);
    };

    const deleteAlias = async (aliasId: number) => {
        const aliasToDelete = aliases.find(alias => alias.id === aliasId);
        if (!aliasToDelete) return;

        try {
            await axiosInstance.post(`/api/order-submission/vendor/alias/delete/${aliasToDelete.id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            setAliases(aliases.filter(alias => alias.id !== aliasToDelete.id));
        } catch (error) {
            console.error('Delete failed:', error);
        }
    };
    function parseNullableIntToString(value: string | undefined): string | null {
        if (value === undefined || value.trim() === "") {
            return null;
        }
        return value;
    }

    const handleDownload = () => {
        if (fileTemplate) {
            const byteCharacters = atob(fileTemplate.fileData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileTemplate.fileName;
            link.click();
        }

    };

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <div className="flex">
                    <div className="w-1/2 p-4 ml-10">
                        <FormField
                            control={form.control}
                            name="orderSubmissionSourceId"
                            render={({ field }) => (
                                //No matter what changes I make to this I cannot get the existing value to load in the list. 
                                //I have loaded everything from 1 endpoint and set the values all at the same time
                                <FormItem>
                                    <FormLabel>Order Submission Sources</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a parent source" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {orderSubmissionList.map(email => (
                                                <SelectItem key={email.item1} value={email.item2.toString()}>
                                                    {email.item1}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorNameFromFile"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Vendor Name From File</FormLabel>
                                    <FormControl>
                                        <Input autoComplete="off" placeholder="Vendor Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorProvidedAccountNumber"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Vendor Provided Account Number</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Account number for vendor" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="sendFromEmailSourceModelId"
                            render={({ field }) => (
                                //No matter what changes I make to this I cannot get the existing value to load in the list. 
                                //I have loaded everything from 1 endpoint and set the values all at the same time
                                <FormItem>
                                    <FormLabel>Send from Email Source</FormLabel>
                                    <Select onValueChange={field.onChange} value={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select an email" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {emailList.map(email => (
                                                <SelectItem key={email.item1} value={email.item2.toString()}>
                                                    {email.item1}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button variant="outline" type="button" onClick={addAlias} className="mt-4 p-2">Add Vendor Alias</Button>
                        {editingAlias && (
                            <div className="mt-8 p-4 border border-gray-300">
                                <h3>Edit Vendor Alias</h3>
                                <div className="mt-2">
                                    <Input
                                        className="my-4"
                                        value={editingAlias.vendorAliasNameFromFile}
                                        onChange={(e) => setEditingAlias({ ...editingAlias, vendorAliasNameFromFile: e.target.value })}
                                        placeholder="Vendor Alias Name"
                                    />
                                    <Input
                                        className="my-4"
                                        value={editingAlias.vendorProvidedAccountNumber}
                                        onChange={(e) => setEditingAlias({ ...editingAlias, vendorProvidedAccountNumber: e.target.value })}
                                        placeholder="Account number for vendor"
                                    />
                                    <Button variant="outline" onClick={() => saveAlias(editingAlias)} className="p-2">Save</Button>
                                    <Button variant="outline" onClick={() => setEditingAlias(null)} className="p-2 ml-2">Cancel</Button>
                                </div>
                            </div>
                        )}
                        <div className="mt-4">
                            {aliases.length > 0 && <FormLabel>Vendor Aliases</FormLabel>}
                            {aliases.map(alias => (
                                <div key={alias.id} className="flex items-center space-x-4">
                                    <span>{alias.vendorAliasNameFromFile} ({alias.vendorProvidedAccountNumber})</span>
                                    <Button variant="outline" size="sm" type="button" onClick={() => editAlias(alias)} className="my-4">Edit</Button>
                                    <Button variant="destructive" size="sm" type="button" onClick={() => deleteAlias(alias.id)} className="my-4">Delete</Button>
                                </div>
                            ))}
                        </div>
                        <FormField
                            control={form.control}
                            name="submitEmails"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Emails</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email addresses" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitCcEmails"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Cc Emails</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email addresses" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitBccEmails"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Bcc Emails</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Email addresses" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitEmailSubjectLine"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Email Subject Line</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Subject Line" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitEmailBodyText"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit Email Body Text</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Body Text" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorWantsTrimmedItemDescription"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants Trimmed Item Description</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {form.watch('vendorWantsTrimmedItemDescription') && (
                            <>
                                <FormField
                                    control={form.control}
                                    name="vendorRegexForItemDescription"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Vendor Regex For Item Description</FormLabel>
                                            <FormControl>
                                                <Input placeholder="Regex Pattern" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                            </>
                        )}
                        <FormField
                            control={form.control}
                            name="vendorWantsAllAlignedLeft"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants All Data Aligned Left</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorWantsNonDailySubmission"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants Non-Daily Submission</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {form.watch('vendorWantsNonDailySubmission') && (
                            <>
                        <FormField
                            control={form.control}
                            name="vendorCustomSubmitEveryXDay"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Vendor Custom Submit Every X Day</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Days" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                                />
                            </>
                        )}
                        <FormField
                            control={form.control}
                            name="vendorWantsPDF"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants PDF</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorWantsSpreadsheet"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants Spreadsheet</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorWantsForwardedEmail"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants Email Forwarded</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="vendorWantsAllData"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Vendor Wants All Data</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitFileHeaderRowIndex"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Submit File Header Row Index</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Header Row Index" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="submitFileTemplate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Upload File Template</FormLabel>
                                    <FormControl>
                                        <Input type="file" accept=".xls,.xlsx" onChange={(e) => {
                                            if (e.target.files && e.target.files[0]) {
                                                field.onChange(e.target.files[0]);
                                            }
                                        }} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {fileTemplate && (
                            <div className="mt-4">
                                <p>Existing File: {fileTemplate.fileName}</p>
                                <Button variant="outline" type="button" onClick={handleDownload}>Download File</Button>
                            </div>
                        )}
                        <FormField
                            control={form.control}
                            name="activeStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Active Status</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="deletedStatus"
                            render={({ field }) => (
                                <FormItem className="flex items-center my-4">
                                    <FormLabel className="mr-2">Deleted Status</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} className="my-0"
                                            onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button className="mb-4" variant="outline" type="submit">Save</Button>
                        <h1>Useable wildcards in email text {wildCardOptions}</h1>
                    </div>
                    <div className="w-1/2 p-4 ml-10">
                        <h1>All Map fields can map order data to multiple columns just separate them with a ,</h1>
                        <FormField
                            control={form.control}
                            name="mapBatch"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Batch</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Batch" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="mapRep"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Rep</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Rep" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapVendorAddress"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Address</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Address" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapVendorPhone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Phone</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Phone" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapVendorFax"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Fax</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Fax" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapPo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map PO</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map PO" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapOrderDate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Order Date</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Order Date" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapReqShipDate"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Req Ship Date</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Req Ship Date" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapShipVia"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Ship Via</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Ship Via" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapShipTo"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Ship To</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Ship To" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapAddress1"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Address 1</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Address 1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapCareOf"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Care Of</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Care Of" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapAttention"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Attention</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Attention" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapAddress2"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Address 2</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Address 2" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapCity"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map City</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map City" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapState"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map State</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map State" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapZipCode"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Zip Code</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Zip Code" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapPhone"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Phone</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Phone" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapEmail"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Email</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Email" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapItemDescription"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Item Description</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Item Description" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapUnitPrice"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Unit Price</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Unit Price" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapQty"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Qty</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Qty" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="mapProvidedAccountNumber"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Vendor Provided Account Number</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Vendor Provided Account Number" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="mapTotal"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Total</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Total" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="staticFileData1"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Static File Data 1</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Data 1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapStaticFileData1"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Static File Data 1</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Data 1" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="staticFileData2"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Static File Data 2</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Data 2" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapStaticFileData2"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Static File Data 2</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Data 2" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="staticFileData3"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Static File Data 3</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Data 3" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapStaticFileData3"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Static File Data 3</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Data 3" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="staticFileData4"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Static File Data 4</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Data 4" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapStaticFileData4"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Static File Data 4</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Data 4" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="staticFileData5"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Static File Data 5</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Data 5" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="mapStaticFileData5"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Map Static File Data 5</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Map Data 5" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
            </form>
        </Form>
    );
}
